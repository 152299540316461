.round-image-container {
    position: relative;
    display: inline-block;
    overflow: hidden;
    border-radius: 50%; 
    cursor: pointer;
}

.image-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
}

.round-image-container:hover .image-overlay {
    opacity: 1;
}