.notebook {
    border-radius: 1rem 1rem 0 0;
    width: 50%;
    z-index: 1;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: height 0.5s ease-in-out;
}

.notebook-closed {
    height: 40px;
}

.notebook-open {
    height: 40vh;
}

.notebook-header {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.notebook-header:hover {
    background-color: #adadad;
    border-radius: 1rem 1rem 0 0;
}

.scrollable {
    position: relative;
}

.scrollable::before {
    content: "";
    height: 20px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    display: none;
}

/* Media Queries for small devices */
@media (max-width: 600px) {
    .notebook {
        width: 90%;
    }

    .notebook-open {
        height: 60vh;
    }

    .notebook-header {
        padding: 0.5rem;
    }

    .notebook-header div {
        font-size: 0.9rem;
    }
}