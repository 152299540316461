body {
    font-family: Arial, sans-serif;
}
.main-container {
    padding: 20px;
}
.intro-text {
    text-align: center;
    margin-top: 70px;
    margin-bottom: 20px;
}

.footer {
    text-align: center;
}

/* Container for the ProcessPanel and SimuLernSteps */
.content {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 600px;
    margin: 40px auto;
}

/* Static ProcessPanel styling */
.process-panel {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    min-width: 50px;
}

.process-icon {
    font-size: 30px;
    color: #333;
    margin-bottom: 10px;
}

.process-line {
    width: 2px;
    height: 30px;
    background-color: #ccc;
    margin-bottom: 10px;
}

/* Steps container styling */
.steps-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: 24px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    transition: background-color 0.2s;
}

.title:first-of-type {
    padding-top: 0;
}

.title:hover {
    background-color: #f0f0f0;
}

.expand-icon {
    transition: transform 0.3s ease;
}

.expand-icon.expanded {
    transform: rotate(180deg);
}



/* Expanded panel styling */
.expanded-panel {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto;
    font-size: 12px;
}




.expanded-panel .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
}



