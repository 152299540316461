.countdown {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);

    padding: 0.5em;
    font-size: 1.2em;
    font-weight: bold;
    color: #fbfbfb;
    /* Classic green for digital clock */
    background-color: #00000082;
    /* Black background */
    border: 2px solid #fdfdfd;
    /* Border to give the effect of digital clock display */
    border-radius: 5px;
    /* Rounded corners to match digital clock display */
    box-shadow: 0 0 10px #ffffff;
    /* Green glow to give the neon effect */

    /* For digital font, you can import a digital font and use it here */
    /* font-family: 'DigitalFont', sans-serif; */

    z-index: 1000;
    /* So that the countdown is over the room */
}

.countdown-last-minute {
    background-color: rgba(255, 0, 0, 0.49);

    /* Blinkig effect */
    animation: blinker 1s linear infinite;

    color: #ffffff;
    /* White color when entering last minute */
    border-color: #ff0000;
    /* Red border color when entering last minute */
    box-shadow: 0 0 10px #ff0000;
    /* Red glow when entering last minute */
}

.countdown-ended {
    background-color: rgb(255, 0, 0);


    color: #ffffff;
    /* White color when countdown ends */
    border-color: #ff0000;
    /* Red border color when countdown ends */
    box-shadow: 0 0 10px #ff0000;
    /* Red glow when countdown ends */
    font-size: 1.2em;
}

@keyframes blinker {

    0%,
    50% {
        background-color: rgba(255, 0, 0, 0.49);
    }


    60% {
        background-color: red;
    }

    70%,
    100% {
        background-color: rgba(255, 0, 0, 0.49);
    }
}