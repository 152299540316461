.team-badge {
    position: absolute;
    top: 10px;
    left: 5px;
    padding: 0.5em;
    font-size: 1em;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    border-radius: 0.5em;
    cursor: pointer;
}

.team-badge:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.view-title-badge {
    position: absolute;
    top: 70px;
    left: 50%;
    transform: translateX(-50%);

    padding: 0.5em;
    font-size: 0.6em;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    border-radius: 0.5em;
}

.end-simulation-badge {
    position: absolute;
    top: 10px;
    right: 5px;
    padding: 0.5em;
    font-size: 1em;
    font-weight: bold;
    color: white;
    background-color: rgba(251, 0, 0, 0.5);
    z-index: 1000;
    border-radius: 0.5em;
    cursor: pointer;
}

.end-simulation-badge:hover {
    background-color: rgba(251, 0, 0, 0.8);
}

.additional-info-button {
    position: fixed;
    bottom: 50px;
    right: 20px;
    z-index: 99;
}

.patient-file-badge-title {
    color: white;
    padding: 0.5em;
    border-radius: 0.5em;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 99;

}

.notebook-badge {
    position: fixed;
    bottom: 10px;
    left: 5px;
    padding: 0.5em;
    font-size: 1em;
    font-weight: bold;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    border-radius: 0.5em;
    cursor: pointer;
}

.notebook-badge:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

